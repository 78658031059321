import cc from "classcat"
import React, { ReactNode, useEffect, useRef } from "react"

import styles from "./ModalFormWrapper.module.css"

type Props = {
  children: ReactNode
  wide?: boolean
  className?: string
  height?: string
  style?: React.CSSProperties
  currentTab?: string
}

const ModalBody = (props: Props) => {
  const {
    children,
    wide,
    className,
    height,
    style = {},
    currentTab = null,
  } = props
  const refModal = useRef(null)

  // Blueprint Tabs persist scroll position when switching tabs
  // in the side panel so we have to reset it to top when the tab changes
  useEffect(() => {
    if (refModal.current && currentTab) {
      refModal.current.scrollTop = 0
    }
  }, [currentTab])

  return (
    <div
      className={cc([
        styles.body,
        className,
        {
          [styles.bodyWide]: wide,
        },
      ])}
      data-test="modal-body"
      style={{
        height,
        maxHeight: height,
        ...style,
      }}
      ref={refModal}
    >
      {children}
    </div>
  )
}

export default ModalBody
