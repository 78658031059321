import React from "react"

const Tick = (props: React.SVGProps<SVGSVGElement> & { fillPath?: string }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 2.25C10.29 2.25 10.1025 2.3325 9.9675 2.4675L4.5 7.9425L2.0325 5.4675C1.8975 5.3325 1.71 5.25 1.5 5.25C1.0875 5.25 0.75 5.5875 0.75 6C0.75 6.21 0.8325 6.3975 0.9675 6.5325L3.9675 9.5325C4.1025 9.6675 4.29 9.75 4.5 9.75C4.71 9.75 4.8975 9.6675 5.0325 9.5325L11.0325 3.5325C11.1675 3.3975 11.25 3.21 11.25 3C11.25 2.5875 10.9125 2.25 10.5 2.25Z"
      fill={props.fillPath || "white"}
    />
  </svg>
)

export default Tick
