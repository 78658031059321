import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./ConvertPersonTagToSkill.module.css"

import { ConvertPersonTagToSkillQuery } from "./__generated__/ConvertPersonTagToSkillQuery.graphql"
import { TagPeopleDeleteMutation } from "~/mutations/__generated__/TagPeopleDeleteMutation.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { skillsUrl } from "~/helpers/routes"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import PersonDetails from "~/common/PersonDetails"
import Button from "~/common/buttons/Button"
import { ChevronDownIcon, CompetencyIcon } from "~/common/react-icons"

import { competenciesCreateRelay } from "~/mutations/Competency"
import { skillsCreateRelay } from "~/mutations/Skill"
import { deletePeopleTagRelay } from "~/mutations/Tag"

import PermissionsProvider from "~/Permissions/PermissionsProvider"
import { withRelayPageContainer } from "~/RelayPageContainer"
import CompetencyDropdown from "~/Skills/Competency/CompetencyDropdown"
import CompetencyTooltipLevel from "~/Skills/Competency/CompetencyTooltipLevel"
import { showToast } from "~/containers/ToasterContainer"

type Props = {
  closeDialog: () => void
  tag: {
    id: number
    name: string
  }
}

const QUERY = graphql`
  query ConvertPersonTagToSkillQuery {
    current_user {
      account {
        id
        allSkills: skills {
          id
          name
        }
        people_tags: tags(where: { model: { _eq: "person" } }) {
          id
          name
          model
          archived
        }
        people {
          id
          first_name
          last_name
          email
          image_key
          tags
          competencies {
            id
            skill {
              id
              name
            }
          }
        }
      }
      ...PermissionsProvider_user
    }
  }
`

const ConvertPersonTagToSkill = (props: Props) => {
  const { tag, closeDialog } = props
  const data = useLazyLoadQuery<ConvertPersonTagToSkillQuery>(QUERY, {})
  const { current_user } = data
  const { allSkills, people } = current_user.account

  const peopleToAddSkillTo = people.filter((person) => {
    const alreadyHasSkill = person.competencies
      .map((c) => c.skill.name)
      .includes(tag.name)
    return !alreadyHasSkill && person.tags.includes(tag.name) // people who have tag
  })

  const [isConverting, setIsConverting] = useState(false)
  const [peopleCompetencies, setPeopleCompetencies] = useState(
    peopleToAddSkillTo.map((p) => {
      return { person: p, competencyLevel: null }
    }),
  )

  const existingSkill = allSkills.find((s) => s.name === tag.name)

  const addCompetencyToPeople = async (skillId: number) => {
    if (!peopleCompetencies.length) {
      return null
    }
    const competencies = peopleCompetencies.map((pc) => {
      return {
        level: pc.competencyLevel,
        personId: pc.person.id,
        skillId: skillId,
      }
    })
    try {
      await competenciesCreateRelay({
        variables: { competencies },
      })
      setIsConverting(false)
    } catch {
      setIsConverting(false)
    }
  }

  const handleConvert = async () => {
    setIsConverting(true)

    // We use peopleWithTags instead of peopleCompetencies
    // in case someone who already has the skill, still has a tag attached
    const peopleIds: TagPeopleDeleteMutation["variables"]["peopleIds"] =
      people.reduce((prev, current) => {
        if (current.tags.includes(tag.name)) {
          return [...prev, current.id]
        }
        return prev
      }, [])

    try {
      track("Tag Convert")
      await deletePeopleTagRelay({ id: tag.id, peopleIds })

      if (existingSkill) {
        await addCompetencyToPeople(existingSkill.id)
      } else {
        await skillsCreateRelay({
          variables: { skills: [{ name: tag.name }] },
        }).then(async (res) => {
          const newSkillId = res.find((skill) => skill.name === tag.name).id
          await addCompetencyToPeople(newSkillId)
        })
      }

      showToast({
        type: "success",
        message: "Tag successfully converted to skill",
        description: tag.name,
        actions: [{ href: skillsUrl() }],
      })
    } catch {
      setIsConverting(false)
    }
  }

  const messageEnding = Boolean(peopleToAddSkillTo.length)
    ? " to the following people:"
    : "."

  return (
    <PermissionsProvider user={current_user}>
      <HasuraContextProvider value={current_user}>
        <ModalFormWrapper headerTitle="Convert Tag to Skill">
          <ModalBody>
            <div className={styles.description}>
              <strong>{tag.name}</strong> will be deleted from tags and added as
              a skill{messageEnding}
            </div>
            {Boolean(peopleToAddSkillTo.length) && (
              <div className={styles.peopleWrapper}>
                {peopleCompetencies.map((p, idx) => {
                  const handleSelectCompetency = (selectedLevel, e) => {
                    e.stopPropagation()
                    const updatedPeopleCompetencies = [...peopleCompetencies]
                    peopleCompetencies[idx].competencyLevel = selectedLevel
                    setPeopleCompetencies(updatedPeopleCompetencies)
                  }

                  return (
                    <div className={styles.personRow} key={p.person.id}>
                      <div className={styles.personDetails}>
                        <PersonDetails
                          person={p.person}
                          fontSize={14}
                          size={32}
                        />
                      </div>

                      <CompetencyDropdown
                        onSelectLevel={handleSelectCompetency}
                        defaultLevel={p.competencyLevel}
                      >
                        <CompetencyTooltipLevel level={p.competencyLevel}>
                          <div
                            data-test={`${p.person.first_name}-competency-button`}
                            className={styles.competencyTarget}
                          >
                            <CompetencyIcon level={p.competencyLevel} />
                            <div className={styles.chevronWrapper}>
                              <ChevronDownIcon color="var(--shadow)" />
                            </div>
                          </div>
                        </CompetencyTooltipLevel>
                      </CompetencyDropdown>
                    </div>
                  )
                })}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button text="Cancel" onClick={closeDialog} />
            <Button
              text="Convert"
              id="convert-tag-button"
              loading={isConverting}
              outlined={false}
              onClick={handleConvert}
              disabled={isConverting}
            />
          </ModalFooter>
        </ModalFormWrapper>
      </HasuraContextProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainer(ConvertPersonTagToSkill)
