import { dateHelpers } from "@runn/calculations"
import { addBusinessDays, isWeekend, parseISO, subBusinessDays } from "date-fns"
import React from "react"

import { TIME_OFF_TYPES } from "~/ENUMS"

export const dateIsAHoliday = <
  T extends {
    start_date?: string
    leave_type?: string
  },
>(
  timeOffs: readonly T[],
  date: string,
): boolean =>
  timeOffs.some(
    (timeOff) =>
      timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY &&
      timeOff.start_date === date,
  )

export const getMergedHolidays = <T extends TimeOffWithId>(
  holidays: readonly T[],
): T[] => {
  return holidays.reduce<T[]>((acc, item) => {
    if (item.leave_type !== TIME_OFF_TYPES.HOLIDAY) {
      return acc
    }
    const holidayWithDuplicateDate = acc.find(
      (h) => h.start_date === item.start_date,
    )

    if (holidayWithDuplicateDate) {
      return [
        ...acc.filter((a) => a.id !== holidayWithDuplicateDate.id),
        {
          ...holidayWithDuplicateDate,
          overlappingHolidays: holidayWithDuplicateDate.overlappingHolidays
            ? [...holidayWithDuplicateDate.overlappingHolidays, item]
            : [item],
        },
      ]
    }
    return [...acc, item]
  }, [])
}
type SortedHolidays<T extends TimeOff> = {
  holidays: T[]
  others: T[]
}
type MergedHolidays<T extends TimeOff> = {
  mergedHolidays: T[]
  others: T[]
}
export const sortTimeOffsByType = <T extends TimeOffWithId>(
  timeOffs: readonly T[],
): MergedHolidays<T> => {
  const all = timeOffs.reduce<SortedHolidays<T>>(
    (sortedObject, timeOff) => {
      timeOff.leave_type === TIME_OFF_TYPES.HOLIDAY
        ? sortedObject.holidays.push(timeOff)
        : sortedObject.others.push(timeOff)

      return sortedObject
    },
    { holidays: [], others: [] } as SortedHolidays<T>,
  )

  return { mergedHolidays: getMergedHolidays(all.holidays), others: all.others }
}

const isNonWorkingDay = <T extends TimeOff>(
  timeOffs: readonly T[],
  date: Date,
): boolean => {
  return (
    dateIsAHoliday(timeOffs, dateHelpers.formatToRunnDate(date)) ||
    isWeekend(date)
  )
}

// get the the earliest business day
// eg. if the date falls on a holiday that is occurring on a Wednesday, return Tuesday
// eg. if the date falls on a weekend, return Friday
export const getClosestPreviousWorkingDay = <T extends TimeOff>(
  timeOffs: readonly T[],
  date: Date | string,
): DateSet => {
  let newDate = typeof date === "string" ? parseISO(date) : date

  while (isNonWorkingDay(timeOffs, newDate)) {
    newDate = subBusinessDays(newDate, 1)
  }

  return { date: newDate, stringDate: dateHelpers.formatToRunnDate(newDate) }
}

export const getClosestNextWorkingDay = <T extends TimeOff>(
  timeOffs: readonly T[],
  date: Date | string,
): DateSet => {
  let newDate = typeof date === "string" ? parseISO(date) : date

  while (isNonWorkingDay(timeOffs, newDate)) {
    newDate = addBusinessDays(newDate, 1)
  }

  return { date: newDate, stringDate: dateHelpers.formatToRunnDate(newDate) }
}

const dateWithinRange = (
  date: string,
  range: { start: string; end: string },
) => {
  return date >= range.start && date <= range.end
}

export const getNumberOfHolidaysWithinTimeOff = <T extends TimeOff>(
  timeOff: T,
  holidays: ReadonlyArray<T>,
) => {
  return (
    timeOff.leave_type === "annual" &&
    holidays.filter(
      (h) =>
        timeOff.end_date &&
        dateWithinRange(h.start_date, {
          start: timeOff.start_date,
          end: timeOff.end_date,
        }),
    ).length
  )
}

type GetHolidaysOverlappingTimeOffs = {
  timeOffs: readonly TimeOffWithId[]
  range?: {
    start: number
    end: number
  }
}

export const getHolidaysOverlappingTimeOffs = ({
  timeOffs,
  range,
}: GetHolidaysOverlappingTimeOffs): string[] => {
  const timeOffsWithinRange =
    (range &&
      timeOffs
        .filter((a) => Number(a.end_date) >= range.start)
        .filter((a) => Number(a.start_date) <= range.end)) ||
    timeOffs

  const { mergedHolidays, others } = sortTimeOffsByType(timeOffsWithinRange)

  const overlappingHolidays = mergedHolidays.filter((h) =>
    others.some(
      (o) =>
        o.end_date &&
        dateWithinRange(h.start_date, {
          start: o.start_date,
          end: o.end_date,
        }),
    ),
  )

  return overlappingHolidays.map((h) => h.start_date)
}

export const getHolidayNote = (holiday: TimeOff, holidayGroupName: string) => {
  return (
    <>
      <span>
        {holiday.note} {`(${holidayGroupName})`}
      </span>
      {holiday.overlappingHolidays?.map((oh) => {
        return (
          <>
            <br />
            <span>
              {oh.note} {`(${holidayGroupName})`}
            </span>
          </>
        )
      })}
    </>
  )
}

export type TimeOff = {
  start_date: string
  leave_type: string
  end_date?: string
  note?: string
  overlappingHolidays?: TimeOff[]
}

export type TimeOffWithId = TimeOff & {
  id: number
}

type DateSet = {
  date: Date
  stringDate: string
}

type Holiday = {
  uuid: string
  country: string
  date: string
  name: string
  weekday: {
    date: {
      name: string
      numeric: number
    }
    observed: {
      name: string
      numeric: number
    }
  }
  observed: string
  public: boolean
}

type DateObj = {
  date: string
  id?: number
}

export type PublicHoliday = {
  holiday: Holiday
  currentYear: Holiday
  secondYear: Holiday
  thirdYear: Holiday
}

export type CustomHoliday = {
  id: number | null
  name: string
  currentYear: DateObj | null
  secondYear: DateObj | null
  thirdYear: DateObj | null
  autoFocus: boolean
}

export default {
  dateIsAHoliday,
  sortTimeOffsByType,
  getClosestPreviousWorkingDay,
}
