/**
 * @generated SignedSource<<c034706dc847e40be18bffeee7d7fcd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationsQuery$variables = {
  accountId?: number | null | undefined;
  email?: string | null | undefined;
};
export type NotificationsQuery$data = {
  readonly person: ReadonlyArray<{
    readonly email: string | null | undefined;
    readonly id: number;
  }>;
};
export type NotificationsQuery = {
  response: NotificationsQuery$data;
  variables: NotificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = [
  {
    "alias": "person",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "accountId"
              }
            ],
            "kind": "ObjectValue",
            "name": "account_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "email"
              }
            ],
            "kind": "ObjectValue",
            "name": "email"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "people",
    "kind": "LinkedField",
    "name": "people",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bfeac4da2a755804770d71c95a587f16",
    "id": null,
    "metadata": {},
    "name": "NotificationsQuery",
    "operationKind": "query",
    "text": "query NotificationsQuery(\n  $email: String\n  $accountId: Int\n) {\n  person: people(where: {email: {_eq: $email}, account_id: {_eq: $accountId}}) {\n    id\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "9944e0bf4d39f18e95a4d760e8a6761f";

export default node;
